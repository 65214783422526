.withdraw-modal {    
  position: fixed;
  width: 494px;
  height: 424px;
  padding: 42px 47px 56px 43px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  top: 50%;
  left: 50vw;
  transform: translate( -50%, -50% );
  display: flex;
  flex-direction: column;
}

.withdraw-modal h2 {
  color: #374448;
  font-size: 24px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 38px;
}

.withdraw-modal-label {
  margin-bottom: 33px;
  display: block;
  position: relative;
}

.withdraw-sum-label::after {
  content: attr(currency);
  font-size: 16px;
  line-height: 19px;
  color: #6F787B;
  position: absolute;
  right: 11px;
  bottom: 34px;
}

.withdraw-sum-label input {
  padding-right: 30px;
  box-sizing: border-box;
}

.withdraw-modal-label > div:first-of-type {
  font-size: 14px;
  line-height: 16px;
  color: #6F787B;
  font-weight: 600;
  margin-bottom: 4px;
}

.withdraw-modal-label:first-of-type div:last-of-type {
  font-size: 12px;
  line-height: 16px;
  color: #6F787B;
  opacity: 0.7;
  font-weight: 500;
  margin-top: 6px;
}

.withdraw-modal-label:last-of-type div:first-of-type {
  margin-bottom: 5px;
}

.withdraw-modal-label input {
  border: none;
  width: 100%;
  height: 42px;
  border-bottom: solid 1px $primaryColor;
}

.withdraw-modal-btns {
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
}

.withdraw-modal-btns button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  width: 189px;
  height: 49px;
  border-radius: 4px;
}

.withdraw-modal-btns button:first-of-type:disabled {
  background-color: #6F787B;
}

.withdraw-modal-btns button:first-of-type {
  background: $primaryColor;
  color: #fff;
}

.withdraw-modal-btns button:last-of-type {
  border: #BDBDBD 1px solid;
  color: #6F787B;
}

.withdraw-modal-close-btn {
  position: absolute;
  top: 11px;
  right: 12px;
  padding: 5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.withdraw-modal-close-btn-small {
  display: none;
}

.withdraw-modal-close-btn svg {
  width: 100%;
  height: 100%;
  display: block;
}

@media(max-width: 1024px) {
  .withdraw-modal {
    transform: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: unset;
    height: unset;
    padding: 0;
    padding-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    max-height: 100%;
  }

  .withdraw-modal-close-btn {
    top: 0;
    left: 0;
    right: unset;
    height: 58px;
    width: 100%;
    padding: 0;
  }

  .withdraw-modal-close-btn-small {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .withdraw-modal-close-btn svg {
    display: none;
  }

  .withdraw-modal-close-btn-small h4 {
    margin-left: 59px;
    margin-bottom: 0;
    color: #374448;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
  }

  .withdraw-modal-close-btn-small button {
    width: 16px;
    height: 16px;
    background: url('../img/arrow-left.png') no-repeat 50% 50%;
    position: absolute;
    left: 16px;    
  }
  
  .withdraw-modal-btns button:last-of-type {
    display: none;
  }

  .withdraw-modal > form {
    min-width: 266px;
    width: 60%;
    max-width: 600px;
    margin-top: -40px;
  }

  .withdraw-modal h2 {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 18px;
  }

  .withdraw-modal-label .my-select {
    width: unset;
  }

  .withdraw-modal-label > div:first-of-type {
    font-size: 13px;
    line-height: 15px;
  }
}