@charset "utf-8";
@import "./fonts/stylesheet.css";
$primaryColor: #0368AF;
@import "../styles/sign-up-container.scss";
@import '../styles/sign-up.scss';
@import "../styles/side-panel.scss";
@import '../styles/close-cross.scss';
@import '../styles/spinner.scss';
@import '../styles/popup.scss';
@import '../styles/withdraw-modal.scss';
@import "../styles/reg-steps-container.scss";
@import "../styles/reg-step-1.scss";
@import "../styles/reg-step-2.scss";
@import "../styles/reg-step-3.scss";
@import "../styles/reg-step-4.scss";
@import "../styles/reg-step-5.scss";
@import "../styles/done-page.scss";
@import "../styles/confirm.scss";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	font-family: "TT Norms", "Arial", sans-serif;
	font-weight: normal;
	background-color: #fff;
  color: #374448;
}
input, textarea, select, .my-select .my-select__single-value, button {
	font-family: "TT Norms", "Arial", sans-serif;
	font-weight: 400;
	font-size: 16px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	padding: 0;
	margin: 0;
	background-color: inherit;
  resize: none;
}
input:focus, textarea:focus, select:focus, button:focus {
	outline: none;
}
*:focus {
	outline: none;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	outline: none;
	border: none;
	cursor: pointer;
	padding: 0;
	background-color: transparent;
	-moz-user-select: none;
	-webkit-user-select: none;
  user-select: none;
  text-align: left;
}
/* button:focus {
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
} */
a {
	text-decoration: none;
	color: unset;
	cursor: pointer;
}
button::-moz-focus-inner,
a::-moz-focus-inner,
select::-moz-focus-inner {
  border: 0;
  outline: none;
}

a, button, select {
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.app-label input[disabled] {
	background: #F6F6F6; 
  color: rgba(55, 68, 72, 0.7);
  border-color: #E0E0E0;
  padding: 10px 10px;
}
input:invalid {
	outline: none;
	box-shadow: none
}
b, strong {
	font-weight: bold;
}

html, body, #root {
	min-height: 100%;
	display: flex;
	justify-content: stretch;
	flex-grow: 1;
}

#wrapper {
	width: 100%;
}

#inner {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-grow: 1;
}

input::placeholder, textarea::placeholder {
	font-size: 14px;
	line-height: 19px;
	color: #c1c1c1;
}

#inner .has-error,
#inner .has-message {
	position: relative;
}

#inner .has-error input {
	border-bottom: 1px solid #F96488;
}

#inner .has-message::before {
	position: absolute;
	bottom: -2px;
	left: 0;
	transform: translate(0, 100%);
	content: attr(messagetext);
	color:#2FBA8C;
	font-size: 11px;
	line-height: 12px;
	font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

#inner .has-error::before {
	position: absolute;
	bottom: -2px;
	left: 0;
	transform: translate(0, 100%);
	content: attr(messagetext);
	color:#F96488;
	font-size: 11px;
	line-height: 12px;
	font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.error-message {
	position: fixed;
	bottom: 10vh;
	left: 50%;
	transform: translate(-50%);
	border-radius: 4px;
	font-size: 13px;
	color: #F96488;
	background: white;
	z-index: 20;
}

.error-message div {
	padding: 10px;
	background: rgba(246, 145, 177, 0.12);
}

.image-div img {
  max-width: 100%;
  max-height: 100%;
}

h2 {
  font-size: 24px;
  line-height: 28px;
}

h3 {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.input-title, h4 {
  font-size: 13px;
  line-height: 16px;
  color: #6f786b;
  font-weight: bold;
}

.app-checkbox {
  display: flex;
  width: 19px;
  height: 19px;
  border-radius: 2px;
  cursor: pointer;
  background: #E7F1F8;
  transition: ease .2s background-color;
  flex-shrink: 0;
  margin-right: 13px;
}

.app-checkbox.active {
  background: $primaryColor url('../img/tick.svg') 50% 50% no-repeat;
  border: 1px solid $primaryColor;
}

.app-label #code {
  margin-bottom: 20px;
  height: 54px;
  font-size: 42px;
  line-height: 49px;
}

.app-btn {
  font-size: 18px;
  width: 180px;
  height: 49px;
  display: flex;
}

.app-btn.disabled {
  color: #a6afb3;
	background: transparent;
  border: 1px solid #BDBDBD;
}

.app-label .my-select {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.my-select .my-select__control:hover,
.my-select .my-select__control:focus {
	border-bottom: 1px solid $primaryColor;
}

.app-label input:focus, .app-label textarea {
	border-bottom: 1px solid $primaryColor;
}

.my-select .my-select__control{
  width: 100%;
	font-size: 16px;
	color: #4f4f4f;
	cursor: pointer;
	border: 0;
	border-radius: 0;
  border-bottom: solid #E0E0E0 1px;
	padding: 0;
}

.my-select .my-select__value-container {
	height: 40px;
  padding: 10px 0;
}

.my-select .my-select__single-value {
	margin: 0;
  font-weight: 400;
}

.my-select .my-select__input input {
  height: unset;
  margin: 0;
}

.my-select .my-select__indicator-separator {
	display: none;
}

.my-select .my-select__menu {
  font-weight: 400;
  width: max-content;
}

.my-select .my-select__option {
  font-size: 16px;
  max-width: 400px;
}

.my-select__option:hover {
	background: $primaryColor;
	color: #fff;
}

.my-select__option:active {
  background: rgba($primaryColor, 0.8);
  color: white;
}

.institution-types {
  display: flex;
  margin-bottom: 12px;
}

.institution-types .app-check-label-wrapper {
  margin-right: 15px;

  .title-div {
    font-size: 16px;
  }
}

.methods-getting-reward {
  display: flex;
  column-gap: 30px;
  -webkit-column-gap: 30px;
}

.app-check-label-wrapper {
  display: flex;
  row-gap: 10px;
  padding: 10px 0;
  -webkit-row-gap: 10px;
  align-items: center;
  min-height: 48px;
  padding-left: 15px;
  padding-right: 25px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  border: solid 1px #BDBDBD;
  border-radius: 4px;
  user-select: none;
  color: #4F4F4F;
  margin-bottom: 18px;
}

.app-check-label-wrapper:last-of-type {
  margin-right: 0px;
}

.app-check-label-wrapper .title-div {
  font-weight: 500;
  font-size: 18px;
}

.app-radio-container {
  display: flex;
  row-gap: 4px;
  -webkit-row-gap: 4px;
  flex-direction: column;
}

.app-check-label-wrapper.active {
  border-color: $primaryColor;
  color: $primaryColor;
}

.primary-text {
  color: $primaryColor;
  font-size: 14px;
  font-weight: 500;
}

.app-radio {
  box-sizing: border-box;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  border: 1px solid #BDBDBD;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.app-radio.active {
  border-color: $primaryColor;
}

.app-radio.active div {
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: $primaryColor;
  border-radius: 50%;
}

.label-disabled {
  background: rgba(#BDBDBD, .3);
  cursor: default;
  pointer-events: none;
}

.app-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  max-width: 400px;
}

.app-label input, .app-label textarea {
  border: none;
  padding: 8px 0 10px;
  border-bottom: solid #E0E0E0 1px;
  height: 40px;
  box-sizing: border-box;
  margin-top: 6px;
}

.app-label input.error {
  border-bottom: solid #F96488 1px;
}

.clickable-span {
  color: $primaryColor;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.primary-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #fff;
	background: $primaryColor;
	border-radius: 4px;
	border: none;
	cursor: pointer;
}

.secondary-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
  color: #6F787B;
	background: transparent;
	border-radius: 4px;
  border: 1px solid #BDBDBD;
	cursor: pointer;
}

.btns-container {
  display: flex;
  column-gap: 30px;
  -webkit-column-gap: 30px;
  margin-top: 30px;
}

.reg-link {
	color: $primaryColor;
	font-size: 16px;
	line-height: 19px;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
}

.fs-14 {
	font-size: 14px;
}

.logo-inner {
  max-width: 200px;
  max-height: 60px;
}

.logo-inner img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.logo {
  position: absolute;
  top: 32px;
  left: 40px;
  height: 80px;
  right: 0;
	z-index: 1;
	pointer-events: none;	
}

.logo svg {
	cursor: pointer;
	pointer-events: auto;	
}

.copyright {
	font-size: 13px;
	color: #ABB4BB;
	margin-top: -40px;
	margin-left: 40px;	
}


.app-btn-wrap {
	display: inline-block;
	width: max-content;
	height: max-content;
}

.confirm-window {
	position: relative;
	background: white;
	position: fixed;
	width: 434px;
	padding: 28px 45px 32px 39px;
	border-radius: 4px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	top: 50%;
	left: 50vw;
	transform: translate( -50%, -50% );
	display: flex;
	flex-direction: column;
}

.confirm-window span {
	font-size: 18px;
	font-weight: 500;
	overflow-wrap: break-word;
	margin-bottom: 40px;
	padding-right: 20px;
}

.confirm-window-btn {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.confirm-window-btn button {
	height: 44px;
	padding: 0 10px;
	border: 1px solid #BDBDBD;
	font-size: 16px;
	font-weight: 500;
	border-radius: 4px;
	width: 140px;
	width: 100%;
	margin-right: 24px;
}

.confirm-window-btn button:last-of-type {
	background: #ff587f;
	margin-right: 0;
	color: white;
	border: none;
}

.confirm-window-close-btn {
	position: absolute;
	top: 11px;
	right: 12px;
	width: 30px;
	height: 30px;
	cursor: pointer;
	padding: 5px;
}

.confirm-window-close-btn svg {
	width: 100%;
	height: 100%;
}

.custom-switch {
  width: max-content;
  background-color: #E9F7FF;
  display: flex;
  border-radius: 4px;
  margin-top: 10px;
}

.custom-switch > button {
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  transition: all .3s ease;
}

.custom-switch > button:first-of-type {border-radius: 4px 0 0 4px;}
.custom-switch > button:last-of-type {border-radius: 0 4px 4px 0;}

.custom-switch > button.active {
  background-color: $primaryColor;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.12);
  color: white;
}

.custom-switch > button:not(.active) {
  border: 1px solid #CEE9F8;
}

.uppercase {
  text-transform: uppercase;
}

.marb-24 {
  margin-bottom: 24px;
}

@media(max-width: 1024px) {
	textarea, .my-select, button, .primary-btn, .secondary-btn {
		font-size: 12px;
  }
  
  .app-btn {
    width: 120px;
    height: 39px;
  }

	.logo svg {
    width: 108px;
	}

	.pseudo-logo {
		width: 80px;
	}
	
  .app-label {
    margin-bottom: 25px;
  }

	.app-label input, .app-label .my-select, #sign-up-form .app-btn, .app-label textarea {
		width: 100%;
	}

	.sign-in-and-sign-up-container .app-btn {
		margin-bottom: 15px;
		margin-top: 0;
	}

	.my-select .my-select__option, .my-select .my-select__control {
		font-size: 14px;
  }
  
  .institution-types, .methods-getting-reward {
    flex-direction: column;
  }

	.copyright {
    left: 15px;
    bottom: 30px;
	}

	.reg-link {
		font-size: 12px;
  }
  
	.app-btn-wrap {
		width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
	}

	.confirm-window {
		padding: 25px 20px;
		max-width: 420px;
		width: 80vw;
	}

	.confirm-window span {
		font-size: 16px;
	}

	.confirm-window-close-btn {
		width: 25px;
		height: 25px;
	}
	
	.confirm-window-btn button {
		height: 32px;
		width: 40%;
		font-size: 12px;
  }
  
  .logo {
    top: 20px;
    left: 15px;
  }

  .btns-container {
    width: 100%;
  }

  .app-check-label-wrapper,
  .institution-types .app-check-label-wrapper {
    margin: 0 0 20px 0;
  }

  .logo-inner {
    max-width: 150px;
    max-height: 45px;
  }
  
  .app-check-label-wrapper .text-div {
    font-size: 13px;
  }

  .app-check-label-wrapper .title-div {
    font-size: 14px;
  }
}

@media(max-width: 767px) {
	.confirm-window span {
		font-size: 14px;
	}
}

.redgvn {color:#aa0000; display: block;}

