.done-page {
  padding: 140px 100px 60px 60px;

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
}

.done-page-inner {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto;
  grid-template-rows: max-content max-content max-content;
  row-gap: 22px;
  column-gap: 70px;

  &>.text-div {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
  }
}

.data-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.data-item {
  display: flex;
  padding: 0 16px;
  border: 1px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 18px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  b {
    font-weight: 500;
  }
}

.data-item-inner {
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: max-content;
}

.data-item-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
}

.data-item-header {
  font-size: 16px;

  .link-email {
    display: inline-block;
    margin-left: 20px;
    color: $primaryColor;

    &.disabled {
      cursor: default;
      color: #000;
    }
  }
}

.data-item-body {
  border-top: #6F787B 1px dashed;
  padding-bottom: 18px;

  .title-div {
    margin: 13px 0 9px;
    font-size: 16px;
    font-weight: 500;
  }

  .text-div {
    font-size: 13px;
  }
}

.download-file-btn {
  color: $primaryColor;
  font-weight: 400;
  cursor: pointer;
  transition: .2s ease;

  &:hover {
    color: darken($primaryColor, 12%);
  }
}

.request-row {
  padding: 16px 0;
  display: grid;
  height: 70px;
  width: 100%;
  grid-template-areas: 'a b'
    'c .';
  grid-template-columns: auto max-content;
  align-items: center;
  border-top: #6F787B 1px dashed;

  &:first-of-type {

    border-top: none;
  }

  .title-div {
    margin: 0;
  }

  .text-div {
    font-size: 13px;
  }

  .status-div {
    grid-area: b;

    &.waiting {
      color: #F7941D
    }

    &.done {
      color: #2FBA8C
    }
  }
}

.data-item-inner .image-div {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.dz-message .image-div {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-item-inner .image-div.done,
.dz-message .image-div.done {
  background-color: #2FBA8C;

  img {
    width: 50%;
    height: 50%;
  }
}

.data-item-inner .image-div.waiting {
  background-color: #F7941D;
}

.data-item-inner .image-div.wrong {
  background-color: #F96488;
}

.documents-list {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 15px;
}

.documents-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
  }

  &.only-documents-to-read {
    margin-top: -57px;
  }
}

.my-dz-done-page .dz-message {
  margin-bottom: 25px;

  &.has-error::before,
  &.has-message::before {
    text-align: center;
  }
}

.done-page-document {
  display: flex;
  column-gap: 16px;
  // grid-template-columns: 48px auto;
  align-items: flex-start;

  img {
    width: 48px;
  }
}

.done-page-document-column {
  display: flex;
  row-gap: 11px;
  flex-direction: column;
  align-items: flex-start;
}

@media(max-width: 1024px) {
  .done-page {
    padding: 90px 70px 100px 40px;

    h2 {
      font-size: 20px;
    }
  }

  .done-page-inner {
    display: flex;
    flex-direction: column;

    &>.text-div {
      font-size: 14px;
    }
  }

  .done-page-document {
    .dz-container {
      .dz-message {
        width: 180px;
        font-size: 12px;
      }
    }

    img {
      width: 36px;
    }
  }

  .documents-container {
    &.only-documents-to-read {
      margin-top: -24px;
    }

    h3 {
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .data-list {
    margin-bottom: 20px;
  }

  .data-item {
    font-size: 14px;
  }

  .data-item .image-div {
    height: 24px;
    width: 24px;
  }

  .data-item .image-div img {
    width: 16px;
  }

  .data-item-body {
    .title-div {
      font-size: 14px;
      font-weight: 500;
    }

    .text-div {
      font-size: 12px;
    }
  }

  .download-file-btn {
    font-size: 12px;
  }

  .documents-list {
    row-gap: 20px;

    h2 {
      margin-bottom: 0;
    }

    h3 {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }

  .data-item-header {
    font-size: 14px;
  }
}