.sign-in-and-sign-up-container {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.sign-in-and-sign-up-container h1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 30px;
}

.reg-step-form h4 {
  margin-bottom: 16px;
}

@media(max-width:1024px) {
  .sign-in-and-sign-up-container {
    padding-top: 15vh;
    padding-bottom: 20vh;
  }

  .sign-in-and-sign-up-container h1 {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .app-btn, .connect-tour-operator {
    width: 100%;
    height: 38px;
    font-size: 14px;
    margin-bottom: 0;
  }
}