.integration-with-tour-operators {
  height: 226px;
}

.reg-next-btn.reg-step-fourth-btn {
  width: 268px;
}

.connect-tour-operator {
  background-color: $primaryColor;
  color: #fff;
  width: 271px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 28px;
}

.self-encashment-types {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
}

.self-encashment-types .app-check-label-wrapper {
  flex-grow: 1;
  min-height: 82px;
}

#reg-step-fourth-form h3 {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #4F4F4F;
  font-weight: 500;
  margin: 8px 0 24px;
}

.card-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 40px;
  -webkit-column-gap: 32px;
  -webkit-row-gap: 40px;
  margin-bottom: 34px;

  .card {
    width: 240px;
    height: 155px;
    display: flex;
    flex-direction: column-reverse;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 21px 17px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    justify-content: space-between;

    img {
      height: min-content;
      object-fit: scale-down;
      width: min-content;
    }

    &.Sberbank {
      background-color: #4AB261;
    }
    &.AlfaBank {
      background-color: #E51818;
      padding-top: 17px;

      img {
        margin-left: 4px;
      }
    }
  }
}

@media(max-width:1024px) {
  .integration-with-tour-operators {
    height: 166px;
    padding: 13px 18px 28px 15px;
    margin-bottom: 0;
  }

  .integration-with-tour-operators div {
    width: 100%;
  }

  .integration-with-tour-operators-text {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .integration-with-tour-operators .connect-tour-operator {
    margin: 0;
  }

  .reg-next-btn.reg-step-fourth-btn {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .self-encashment-types {
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  .self-encashment-types .app-check-label-wrapper {
    flex-grow: unset;
    flex-basis: unset;
  }

  .card-cards {
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 40px;
    -webkit-column-gap: 32px;
    -webkit-row-gap: 40px;
    margin-bottom: 34px;
  
    .card {
      width: 180px;
      height: 85px;
      background-position: 17px 22px;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column-reverse;
      font-size: 0;
      line-height: 16px;
      color: #FFFFFF;
      padding: 18px 10px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
    }
  }
}