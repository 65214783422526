.side-panel {
  flex-basis: 44%;
  background-color: rgba($primaryColor, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  align-self: stretch;
  justify-content: center;
}

.side-panel-middle{
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 75%;
}

.main-reg-img {
  backdrop-filter: drop-shadow(4px 4px 10px white);
}

.side-panel-top-text {
  font-size: 22px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  color: $primaryColor;
}

.side-panel-bottom-text {
  font-size: 18px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  color: #374448;
}


@media(max-width:1366px) {
  .side-panel-middle {
    transform: scale(.8);
    max-width: 100%;
  }
}