.close-cross-btn-container {
  position: absolute;
  top: 20px;
  right: 17px;
}

.close-cross-btn {
  height: 14px;
  width: 14px;
  display: flex;
  box-sizing: content-box;
  padding: 5px;
}

.close-cross-btn-image {
  background: url('../img/close.png') 50% 50% no-repeat;
  width: 100%;
  height: 100%;
  transition: transform .1s ease;
}

.close-cross-btn:focus .close-cross-btn-image, 
.close-cross-btn:hover .close-cross-btn-image {
  transform: rotate(-90deg);
}