.load-spinner {
  margin: auto;
  background: none;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0,0,0,0.3)
}

.load-spinner svg {
  position: absolute;
  top: 50%;
  left: 50vw;
  transform: translate(-50%, -50%);
}