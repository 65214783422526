.sign-up-forms {
  max-width: 600px;
  min-width: 250px;
  width: 70%;
	display: flex;
	flex-direction: column;
}

.sign-up-forms .app-label {
  margin-bottom: 20px;
}

.agree-with-container {
  display: flex;
  padding: 5px;
  margin: -5px;
  margin-bottom: 35px;
  cursor: pointer;
}

.agree-with {
  font-size: 14px;
  line-height: 21px;
  color: #6F787B;

  a {
    font-weight: 500;
    color: $primaryColor;
  }
}

.sended-sms {
  margin: -20px 0 20px;
}

.additional-notice {
  color:#828282;
  font-size: 14px;
  line-height: 16px;
  margin: 6px 0 10px;
}

.oldNumber-link {
  font-size: 14px;
  margin-top: 4px;
  font-weight: 500;
  padding: 5px;
  margin-left: -5px;
  color: $primaryColor;
}

.reg-tel {
  font-weight: 500;
}

.otp-label {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.otp-btn {
  margin: 10px 0 20px;
}

.otp-style {
  height: 54px;
  width: 165px;
  justify-content: space-between;
  margin-top: 15px;
}

.otp-style.has-error::before {
  max-width: 300px;
  width: max-content;
}

.otp-style > div {
  transform: scale(0.715);
}

.otp-input-style {
  font-size: 42px;
  line-height: 49px;
  color: #333333;
  border: none;
  border-bottom: solid #E0E0E0 2px;
  padding-bottom: 8px;
  width: 1em;
}

.otp-input-style-past {
  border-bottom: solid #828282 2px;
}

.reg-link.fs-14 {
  text-align: left;
  font-weight: 500;
}

#timer-span-2 {
  font-weight: 500;
  color: $primaryColor;
  cursor: pointer;
  user-select: none;
}

@media(max-width:1024px) {
	.sign-up-forms {
		max-width: 500px;
		min-width: 300px;
  }
  
  .additional-notice {
    font-size: 11px;
    width: 100%;
  }

  .otp-style {
    margin-bottom: 0;
    transform: scale(0.9);
    transform-origin: left;
  }
}