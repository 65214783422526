.general-popup {
  position: absolute;
  width: max-content;
	background-color: white;
	padding: 42px 47px 61px 43px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
	border-radius: 8px;
  z-index: 10;
  top: 200px;
  left: 50%;
  transform: translate(-50%);
}

.general-popup .close-btn {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 8px;
  right: 5px;
}

.general-popup .close-btn img {
  width: 100%;
  height: 100%;
}

@media(max-width: 1024px) {
  
}